// WARNING: This is not a self contained Component. It relies on ReactTooltip
// being defined in the same context
import { ChatBubbleIcon } from 'simple-core-ui'
import s from './ChatHover.scss'

const ChatHover = ({ text }: { text: string }) => {
  return (
    <span data-tip={text}>
      <img src={ChatBubbleIcon} className={s.chatBubble} />
    </span>
  )
}

export default ChatHover
