import { useEffect, useState } from 'react'
import { makeDeleteRequest, makePutRequest } from 'utils/api'
import Message from './Message'
import MessageInput from './MessageInput'
import Sidebar from './Sidebar'
import s from '../styles/AIChat.scss'
import { useChatContext } from '../context/ChatContext'
import { IChat, IMessage } from '../types/models'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AIStarsIcon from './AiIcon'
import { Button, Modal, ModalContainer } from 'simple-core-ui'
import swal from 'sweetalert'
import axios, { AxiosError } from 'axios'
import { ScrollArrow, useScrollArrow } from './ScrollArrow'
import useCancelableRequest from 'simple-core-ui/hooks/useCancelableRequest'

const customPrompts = [
  'What is my total spend YTD?',
  'How many invoices are pending payment?',
  'What is the average invoice amount?',
  'Who are my top 5 vendors by spend?'
]

const Chat = () => {
  const [messages, setMessages] = useState<IMessage[]>([])
  const [isLoadingMessage, setIsLoadingMessage] = useState(false)
  const { id } = useParams<{ id?: string }>()
  const [selectedChat, setSelectedChat] = useState<string | null>(id || null)
  const [chats, setChats] = useState<IChat[]>([])
  const [renameChat, setRenameChat] = useState<string>('')
  const [newName, setNewName] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { ref: containerRef, showScrollToBottom, scrollToBottom } = useScrollArrow()
  const { makeCancelableRequest, cancelRequest } = useCancelableRequest()

  const {
    data: { user, urls, prompts, enableOverrides },
    methods
  } = useChatContext()

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [selectedChat, messages])

  async function fetchChatList(selectFirstChat: boolean = false) {
    const response = await fetch(urls.chatList)
    const data = await response.json()
    setChats(data.chats)

    if (selectFirstChat) {
      setSelectedChat(data.chats[0]?.id || null)
    }
  }

  async function fetchChatDetail(cancelable: boolean = false) {
    try {
      const { data } = await makeCancelableRequest(
        { url: `${urls.chatDetail.replace('<id>', selectedChat || '')}`, method: 'GET' },
        cancelable
      )

      setMessages(data.messages)
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        setMessages([])
        navigate(`/v2/reports/aichat`)
      } else if (axios.isCancel(error)) {
        return
      } else {
        dispatch({ type: 'API_ERROR', error })
      }
    }
  }

  const handleDeleteChat = async (id: string) => {
    const deleteConfirmation = await swal({
      title: 'Delete Chat',
      text: `Are you sure you want to delete this chat? This action cannot be undone.`,
      buttons: ['Cancel', 'Delete']
    })

    if (deleteConfirmation) {
      await makeDeleteRequest(`${urls.chatDetail.replace('<id>', id || '')}`)
      await fetchChatList(true)
    }
  }

  const handleRenameChat = async (id: string) => {
    setRenameChat('')
    await makePutRequest(`${urls.updateChat.replace('<id>', id || '')}`, {
      title: newName
    })
    setNewName('')
    await fetchChatList(true)
  }

  useEffect(() => {
    if (selectedChat !== null) {
      fetchChatDetail()
    }
    fetchChatList()
  }, [])

  useEffect(() => {
    if (selectedChat) {
      fetchChatDetail()
      navigate(`/v2/reports/aichat/${selectedChat}`)
    } else if (selectedChat === null) {
      setMessages([])
      navigate(`/v2/reports/aichat`)
    }
    cancelRequest()
  }, [selectedChat])

  const handleSendMessage = async (text: string) => {
    if (text.trim()) {
      setIsLoadingMessage(true)
      const newUserMessage: IMessage = {
        text,
        id: messages.length + 1,
        role: 'user',
        graph_definition: undefined,
        is_visible: true,
        message_type: 'user_message',
        created_date: null
      }
      setMessages(prevMessages => [...prevMessages, newUserMessage])

      if (selectedChat !== null && selectedChat !== undefined) {
        newUserMessage.chat_id = selectedChat
      }
      let data

      try {
        const response = await makeCancelableRequest({
          url: urls.submitMessage,
          data: {
            ...newUserMessage,
            // PROMPT REFINEMENT IF USER IS CSM
            ...(window.credentials.user.isCSM && enableOverrides
              ? {
                  prompt_overrides: prompts.reduce((acc, cur) => {
                    acc[cur.name] = cur.content
                    return acc
                  }, {})
                }
              : {})
          },
          method: 'POST'
        })
        data = response.data
      } catch (e) {
        if (axios.isCancel(e)) {
          setIsLoadingMessage(false)
          return
        }
        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Something went wrong',
            message: JSON.stringify(e),
            level: 'error'
          }
        })
        return
      }

      if (!selectedChat) {
        setSelectedChat(data.chat_id)
      } else {
        await fetchChatDetail(true)
      }
      setIsLoadingMessage(false)

      await fetchChatList()
    }
  }

  const handleSelectChat = (id: string) => {
    setSelectedChat(id)
  }

  const handleNewChat = () => {
    setSelectedChat(null)
  }

  return (
    <div className={s['chat-app']}>
      <Sidebar
        selectedChat={selectedChat}
        chats={
          selectedChat
            ? chats
            : [
                {
                  id: null,
                  name: 'New Chat',
                  last_message_date: new Date().toISOString()
                },
                ...chats
              ]
        }
        onSelectSession={handleSelectChat}
        handleNewChat={handleNewChat}
        handleDeleteChat={handleDeleteChat}
        handleRenameChat={setRenameChat}
      />
      <div className={s['chat-container']}>
        {!messages.length ? (
          <div className={s['empty-state']}>
            <h2>Hi {user.firstName}</h2>
            <AIStarsIcon style={{ width: '80px' }} />
            <p>Get the answers you need just by asking AI</p>
            <section className={s['custom-prompts']}>
              {customPrompts.map((prompt, index) => (
                <article key={index} onClick={() => handleSendMessage(prompt)}>
                  {prompt}
                </article>
              ))}
            </section>
          </div>
        ) : (
          <div className={s['message-container']} ref={containerRef}>
            {messages
              .filter(m => m.is_visible)
              .map(message => (
                <Message key={message.id} message={message} />
              ))}
            {isLoadingMessage && (
              <Message
                key="loading"
                message={{
                  id: messages.length + 1,
                  text: '',
                  graph_definition: undefined,
                  is_visible: true,
                  role: 'assistant',
                  message_type: 'loading',
                  created_date: null
                }}
              />
            )}
            {showScrollToBottom && <ScrollArrow scrollToBottom={scrollToBottom} />}
            {renameChat !== '' && (
              <Modal
                title="Rename Chat"
                size="sm"
                hasNewButtons
                cancelText="Cancel"
                confirmText="Save"
                isVisible={renameChat !== ''}
                content={
                  <div>
                    <div style={{ marginBottom: 12 }}>Chat Name</div>
                    <input type="text" value={newName} onChange={e => setNewName(e.target.value)} />
                  </div>
                }
                confirmCb={() => handleRenameChat(renameChat)}
                cancelCb={() => setNewName('')}
              />
            )}
            {window.credentials.user.isCSM ? (
              <ModalContainer
                title="Logic"
                content={
                  <div>
                    <h3>Message Logic</h3>
                    <ul>
                      {messages.map(message => {
                        return (
                          <li style={{ margin: '12px 0', whiteSpace: 'pre-line' }} key={message.id}>
                            <strong>{message.role}:</strong> {message.text}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                }
                cancelText="Close"
                cancelCb={() => null}
              >
                {(openModal: () => void) => {
                  return (
                    <Button
                      isSecondary
                      style={{ position: 'absolute', top: 12, right: 8 }}
                      onClick={openModal}
                    >
                      View Logic
                    </Button>
                  )
                }}
              </ModalContainer>
            ) : null}
          </div>
        )}
        <MessageInput onSendMessage={handleSendMessage} />
      </div>
    </div>
  )
}

export default Chat
