import s from './Users.scss'
import { FaSearch } from 'react-icons/fa'
import {
  Button,
  CheckboxContainer,
  AvatarList,
  LoadingSkeleton,
  Ellipsis,
  sortAlphabeticallyByProperty,
  SCOPE
} from 'simple-core-ui'
import { useState, useEffect, CSSProperties } from 'react'
import { makeGetRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { toUsers } from './serializers'
import debounce from 'debounce-promise'
import { APIUser, Option } from './types'
import InfiniteScroll from 'react-infinite-scroll-component'
import uniqBy from 'lodash/unionBy'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'

interface Props {
  togglePopper?: () => void
  value: Option[]
  requestParams: {
    active?: boolean
    matterId?: number
    canEdit?: boolean
    canRead?: boolean
    vendorId?: number
    giveFloatingUsers?: boolean
    filterFields?: string
    vrn?: string
    mId?: number | string
    useVendorHash?: boolean
  }
  style?: CSSProperties
  onConfirm: (value: Option[]) => void
  readOnly?: boolean
  scope?: string
  filterKey?: string
  showEmail?: boolean
  isCg?: boolean
  hideLoginUsers?: boolean
  serializer?: (options: APIUser[], filterKey: string) => Option[]
  url?: string
  disallowRemoval?: boolean
}

const PAGE_SIZE = 20

const cgAvatarStyles = {
  border: '2px solid #ee7b77',
  background: 'white',
  color: '#ee7b77'
}

const Users = ({
  togglePopper,
  onConfirm,
  value,
  requestParams = {},
  style = {},
  readOnly,
  scope,
  filterKey = 'full_name',
  showEmail = false,
  isCg,
  hideLoginUsers = false,
  serializer,
  disallowRemoval,
  url = ''
}: Props) => {
  const [users, setUsers] = useState<Option[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Option[]>(value)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const serialiser = serializer || toUsers

  const confirmButtonProps = {
    className: cn({ [s.cgConfirmButton]: isCg }),
    hasNewDesign: !isCg,
    isPrimary: !isCg,
    isCg
  }

  const fetchUsers = async (search = '', firstPage = false) => {
    let response
    let localPage = firstPage ? 0 : page
    let localHasMore = hasMore
    let localUsers = selectedUsers

    const getResponse = async () => {
      const response = await makeGetRequest(url || '/manage/contacts/search/', {
        params: {
          p: localPage,
          n: PAGE_SIZE,
          q: search,
          ...(hideLoginUsers ? { l: false } : { l: true }),
          ...(requestParams.vrn ? { vrn: requestParams.vrn } : {}),
          ...(requestParams.filterFields ? { ff: requestParams.filterFields } : { ff: 'fnln' }),
          ...(requestParams.active ? { i: true } : {}),
          ...((!scope || scope === SCOPE.MATTER) && requestParams.matterId
            ? { tm: requestParams.matterId }
            : {}),
          ...(scope === SCOPE.VENDOR && requestParams.vendorId
            ? { v: requestParams.vendorId }
            : {}),
          ...(requestParams.canEdit ? { ce: true } : {}),
          ...(requestParams.canRead ? { cr: true } : {}),
          ...(requestParams.giveFloatingUsers ? { f: true } : {}),
          ...(requestParams.mId ? { m: requestParams.mId } : {}),
          ...(requestParams.useVendorHash ? { vh: true } : {})
        }
      })
      return response
    }

    if (firstPage) {
      if (search) {
        const response = await getResponse()
        setUsers(serialiser(response.results, filterKey))
        setPage(1)
        setHasMore(response.more)
        return
      } else {
        localPage = 0
        localHasMore = true
      }
    }

    while (localHasMore) {
      try {
        response = await getResponse()
        localHasMore = response.more
      } catch (error) {
        dispatch({ type: 'API_ERROR', error })
        return {
          hasMore: false
        }
      }
      const newUsers = serialiser(response.results, filterKey)
      const filteredUsers = newUsers.filter(
        user => !selectedUsers.find(selectedUser => selectedUser.value === user.value)
      )
      if (filteredUsers.length > 0) {
        localUsers = [...localUsers, ...filteredUsers]
        localPage += 1
        if (filteredUsers.length === PAGE_SIZE) break
      } else {
        localPage += 1
      }
    }
    setUsers(sortAlphabeticallyByProperty(uniqBy([...users, ...localUsers], 'value'), 'label'))
    setPage(localPage)
    setHasMore(localHasMore)
  }

  const debouncedFetchUsers = debounce((search: string) => fetchUsers(search, true), 400)

  useEffect(() => {
    if (users.filter(u => !selectedUsers.find(su => u.value === su.value)).length === 0) {
      fetchUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [users, selectedUsers])

  const toggleCheck = (option: Option) => {
    const arr = [...selectedUsers]
    const index = arr.findIndex(e => e.value === option.value)
    if (index !== -1) {
      arr.splice(index, 1)
      setSelectedUsers(arr)
    } else {
      setSelectedUsers(sortAlphabeticallyByProperty([...arr, option], 'label'))
    }
  }

  return (
    <div style={style}>
      <div className={s.searchInputContainer}>
        <FaSearch className={s.searchIcon} />
        <input
          onChange={e => {
            const { value } = e.target
            debouncedFetchUsers(value)
          }}
          className={s.inputCheckbox}
          placeholder="Search"
        />
      </div>
      <div className={s.searchDivider} />
      <div className={s.selectedUsersWrapper}>
        {sortAlphabeticallyByProperty(selectedUsers, 'label').map(option => {
          return (
            <div className={s.checkboxOption} key={option.value}>
              <CheckboxContainer
                styles={{
                  borderColor: isCg ? '#ee7b77' : '#c4c4c4',
                  border: `1px solid ${isCg ? '#ee7b77' : '#c4c4c4'}`,
                  borderRadius: '4px',
                  marginTop: 2
                }}
                hasGreyBorder
                size="sm"
                isChecked={
                  selectedUsers ? !!selectedUsers.find(el => el.value === option.value) : false
                }
                cb={() => toggleCheck(option)}
                disabled={readOnly || disallowRemoval ? selectedUsers.length === 1 : false}
                isCgOutline={isCg}
              />
              <div className={s.option}>
                {filterKey === 'full_name' && (
                  <AvatarList
                    size="md"
                    wrapperStyles={{ width: 36 }}
                    avatarStyles={{ marginLeft: 0, ...(isCg ? cgAvatarStyles : {}) }}
                    entries={[{ label: option.label }]}
                  />
                )}
                <div className={s.details}>
                  <label className={s.checkboxLabel}>
                    <Ellipsis width={300} lines={2}>
                      {option.label}
                    </Ellipsis>
                  </label>
                  {showEmail ? <p className={s.email}>{option.email}</p> : null}
                </div>
              </div>
            </div>
          )
        })}
      </div>

      {selectedUsers.length > 0 && <div className={s.divider} />}

      <div id="scrollableDiv" className={s.scrollableDiv}>
        <InfiniteScroll
          dataLength={users.length}
          next={fetchUsers}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          loader={
            <>
              {Array(5)
                .fill(0)
                .map((_, i) => (
                  <section
                    key={i}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '1em',
                      paddingBottom: 0
                    }}
                  >
                    {/* @ts-expect-error */}
                    <LoadingSkeleton height={28} width={28} circle />
                    {/* @ts-expect-error */}
                    <LoadingSkeleton height={20} width={330} />
                  </section>
                ))}
            </>
          }
        >
          {users
            .filter(u => !selectedUsers.find(su => u.value === su.value))
            .map(option => (
              <div className={s.checkboxOption} key={option.value}>
                <CheckboxContainer
                  styles={{
                    borderColor: isCg ? '#939393' : '#c4c4c4',
                    border: `1px solid ${isCg ? '#939393' : '#c4c4c4'}`,
                    borderRadius: '4px',
                    marginTop: 2
                  }}
                  hasGreyBorder
                  size="sm"
                  isChecked={
                    selectedUsers ? !!selectedUsers.find(el => el.value === option.value) : false
                  }
                  cb={() => toggleCheck(option)}
                  disabled={readOnly}
                  isCgOutline={isCg}
                />
                <div className={s.option}>
                  {filterKey === 'full_name' && (
                    <AvatarList
                      size="md"
                      avatarStyles={{ marginLeft: 0, ...(isCg ? cgAvatarStyles : {}) }}
                      wrapperStyles={{ width: 36 }}
                      entries={[{ label: option.label }]}
                    />
                  )}
                  <div className={s.details}>
                    <label className={s.checkboxLabel}>
                      <Ellipsis width={300} lines={2}>
                        {option.label}
                      </Ellipsis>
                    </label>
                    {showEmail ? <p className={s.email}>{option.email}</p> : null}
                  </div>
                </div>
              </div>
            ))}
        </InfiniteScroll>
      </div>

      <div className={s.footer}>
        <Button
          hasNewDesign
          isPrimary
          isOutline
          onClick={() => {
            togglePopper?.()
          }}
          isCg={isCg}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            togglePopper?.()
            onConfirm?.(selectedUsers)
          }}
          isDisabled={readOnly}
          {...confirmButtonProps}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

export default Users
