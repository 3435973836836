import React, { useState } from 'react'
import { useChatContext } from '../context/ChatContext'
import s from '../styles/AIChat.scss'
import AutoGrowTextarea from './AutoGrowTextarea'

interface MessageInputProps {
  onSendMessage: (text: string) => void
}

const MessageInput: React.FC<MessageInputProps> = ({ onSendMessage }) => {
  const {
    data: { urls }
  } = useChatContext()

  const [message, setMessage] = useState<string>('')

  const handleSend = () => {
    onSendMessage(message)
    setMessage('')
  }

  return (
    <section className={s['message-input-container']}>
      <p>
        Ask your question here (
        <a href={urls.examplePrompts}>
          <i>View Examples</i>
        </a>
        )
      </p>
      <div className={s['message-input']}>
        <AutoGrowTextarea
          value={message}
          onChange={setMessage}
          placeholder=""
          onKeyDown={e => e.key === 'Enter' && handleSend()}
        />
      </div>
      <div className={s['message-input-footer']}>
        <span>
          AskAI may display inaccurate info so double-check its responses. Data updated once every
          24 hours.
        </span>
        <button
          disabled={message ? false : true}
          className={s['submit-button']}
          onClick={handleSend}
        >
          Submit
        </button>
      </div>
    </section>
  )
}

export default MessageInput
