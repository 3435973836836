import { useRef, useEffect } from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

const useCancellableRequest = () => {
  const abortControllerRef = useRef<AbortController | null>(null)

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [])

  const cancelRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
  }

  const makeCancelableRequest = (
    requestConfig: AxiosRequestConfig,
    cancellable: boolean = true
  ) => {
    if (cancellable) {
      abortControllerRef.current = new AbortController()
    }
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios({
        ...requestConfig,
        ...(cancellable ? { signal: abortControllerRef.current?.signal } : {})
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return { cancelRequest, makeCancelableRequest }
}

export default useCancellableRequest
