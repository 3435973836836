import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  SetStateAction,
  Dispatch
} from 'react'
import { makeGetRequest } from 'utils/api'

interface ChatContextProps {
  data: {
    urls: {
      examplePrompts: string
      chatDetail: string
      chatList: string
      submitMessage: string
      createChat: string
      deleteChat: string
      updateChat: string
    }
    theme?: string
    user: {
      firstName: string
      lastName: string
    }
    prompts: Record<string, string>[]
    enableOverrides: boolean
  }
  methods: {
    setPrompts: Dispatch<SetStateAction<Record<string, string>[]>>
    setEnableOverrides: Dispatch<SetStateAction<boolean>>
  }
}

const defaultContext: ChatContextProps = {
  data: {
    theme: 'default',
    user: {
      firstName: 'John',
      lastName: 'Doe'
    },
    urls: {
      examplePrompts: 'https://example.com',
      chatDetail: 'https://example.com/chat-detail',
      chatList: 'https://example.com/chat-list',
      submitMessage: 'https://example.com/submit-message',
      createChat: 'https://example.com/create-chat',
      deleteChat: 'https://example.com/delete-chat',
      updateChat: 'https://example.com/update-chat'
    },
    prompts: [],
    enableOverrides: false
  },
  methods: {
    setPrompts: () => {},
    setEnableOverrides: () => {}
  }
}

const ChatContext = createContext<ChatContextProps>(defaultContext)

export const ChatProvider = ({
  initialState,
  children
}: {
  initialState?: Omit<ChatContextProps, 'methods'>
  children: ReactNode
}): JSX.Element => {
  const [prompts, setPrompts] = useState<Record<string, string>[]>([])
  const [enableOverrides, setEnableOverrides] = useState<boolean>(false)

  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await makeGetRequest('/reports/prompts/')
        setPrompts(response.prompts)
      } catch (e) {
        console.error(e)
        return
      }
    }

    if (window.credentials.user.isCSM) {
      fetchPrompts()
    }
  }, [])

  return (
    <ChatContext.Provider
      value={
        initialState
          ? {
              data: { ...initialState.data, prompts, enableOverrides },
              methods: {
                setPrompts,
                setEnableOverrides
              }
            }
          : defaultContext
      }
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChatContext = () => {
  return useContext(ChatContext)
}
