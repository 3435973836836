import { KeyboardEventHandler, useEffect, useRef } from 'react'
import s from '../styles/AIChat.scss'

const AutoGrowTextarea = ({
  value,
  placeholder,
  onChange,
  onKeyDown
}: {
  value: string
  placeholder: string
  onChange: (arg: string) => void
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '40px'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px` // Set it to match content
    }
    onChange(textareaRef.current?.value || '')
  }

  useEffect(() => {
    if (value === '' && textareaRef.current) {
      textareaRef.current.style.height = '40px'
    }
  }, [value])

  useEffect(() => {
    handleInput()
  }, [])

  return (
    <textarea
      ref={textareaRef}
      value={value}
      placeholder={placeholder}
      onInput={handleInput}
      className={s.textarea}
      onKeyDown={onKeyDown}
    />
  )
}

export default AutoGrowTextarea
